.messages {
    isolation: isolate;
    padding: 0;
    list-style: none;
}

.messages>li * {
    user-select: text;
}

.messages__welcome {
    margin: 12px;
    color: var(--primary-color);
    font-size: 1.4rem;
    text-align: center;
}