.form-input {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    width: 100%;
}

.form-input__text {
    order: 2;
    color: var(--primary-color);
    text-wrap: nowrap;
}

.form-input__label {
    position: absolute;
    z-index: 1;
    top: -0.6rem;
    right: 12%;
    font-size: 1rem;
}

.form-input__description {
    overflow: hidden;
    font-size: 0.8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: opacity var(--transition-duration);
}

.form-input__body {
    position: relative;
    display: flex;
    align-items: center;
    order: 1;
}

.form-input__icon,
.form-input__eye {
    position: absolute;
    padding: var(--padding-sm) var(--padding);
    fill: var(--primary-color);
    line-height: 0;
}

.form-input__eye {
    right: 0;
    cursor: pointer;
}

.form-input__icon svg {
    width: 24px;
    height: 24px;
}

.form-input--compact {
    flex-shrink: 0;
}

.form-input--compact .form-input__text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
}

.form-input--compact .form-input__label {
    position: static;
}

.form-input--compact .form-input__body {
    order: 2;
}