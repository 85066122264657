.invalid-file-size {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.invalid-file-size__text {
    color: var(--font-color);
}

.invalid-file-size__text>span {
    color: var(--primary-color);
}

.invalid-file-size__button {
    display: flex;
    justify-content: flex-end;
}