.community-members-presence {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.community-members-presence__label {
    display: flex;
    justify-content: space-between;
    gap: var(--gap);
    color: var(--font-color);
}

.community-members-presence__members {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
}

.community-members-presence__member {
    display: flex;
    align-items: center;
    padding: var(--padding-sm);
    border-radius: var(--border-radius);
    cursor: pointer;

    &:hover {
        background: var(--hover-color);
    }

    &>svg {
        width: 1rem;
        height: 1rem;
        fill: gold;
    }
}