.error-message {
    display: flex;
    align-items: center;
    gap: var(--gap-lg);
    padding: var(--padding-sm) var(--padding);
    border-radius: var(--border-radius);
    background: linear-gradient(90deg,
            color-mix(in hsl, var(--error-color), transparent 70%),
            transparent);
    color: var(--error-color);

    & svg {
        width: 24px;
        height: 24px;
        fill: var(--error-color);
    }
}