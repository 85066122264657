.modal {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    inset: 0;
}

.modal--closed {
    pointer-events: none;
    visibility: hidden;
}

.modal__backdrop,
.modal__dialog {
    transition-property: visibility, opacity, translate, scale;
    transition-duration: var(--transition-duration);
}

.modal__backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

.modal--closed .modal__backdrop {
    opacity: 0;
}

.modal__dialog {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 450px;
    max-height: 90%;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: var(--shadow-options-lg) var(--shadow-color);
    background: var(--background-color-2);
    translate: 0;
}

.modal--closed .modal__dialog {
    opacity: 0;
    translate: -500px;
    scale: 0.6;
    transform-origin: 3000px;
}

.modal__header,
.modal__content,
.modal__footer {
    padding: var(--padding)
}

.modal__header,
.modal__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    z-index: 1;
    overflow: hidden;
    background: var(--background-color-1);
    box-shadow: var(--shadow);
    color: var(--primary-color);
    font-size: 1.4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.modal__wrapper {
    display: grid;
    align-items: center;
    overflow-y: clip;
    transition-property: height, box-shadow, opacity;
    transition-duration: var(--transition-duration);
    transition-timing-function: ease-out;
}

.modal__content,
.modal__footer {
    min-width: 0;
}