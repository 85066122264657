.screen {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    inset: 0;
    background: var(--background-color-2);
    transition-property: opacity, visibility;
    transition-duration: var(--transition-duration);
}

.screen--closed {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
}

.screen__content,
.screen__footer {
    padding: var(--padding);
}

.screen__content {
    height: 100%;
}

.screen__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    background: var(--background-color-1);
    box-shadow: var(--shadow);
    color: var(--primary-color);
    font-size: 1.4rem;
    white-space: nowrap;
}