.progress-bar {
    display: grid;
    gap: var(--gap);
    padding: var(--padding);
    border-radius: var(--border-radius);
    background: var(--background-color-1);
}

.progress-bar>div * {
    user-select: none;
}

.progress-bar__label {
    display: flex;
    justify-content: space-between;
    gap: var(--gap);
    color: var(--font-color);
}

.progress-bar__bar {
    height: 8px;
    overflow: hidden;
    border-radius: var(--border-radius);
    background: var(--background-color-3);
}

.progress-bar__progress {
    height: 100%;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    transition: width var(--transition-duration);
}