.button {
    position: relative;
    padding: 0;
    border: none;
    background: none;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: color-mix(in hsl, var(--button-color), transparent) solid underline;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button:hover:enabled {
    text-decoration: var(--button-color) solid underline;

    & svg {
        fill: var(--font-color);
    }
}

.button--primary {
    --button-color: var(--primary-button-color);
}

.button--secondary {
    --button-color: var(--secondary-button-color);
}

.button__content {
    display: flex;
    align-items: center;
    gap: var(--gap);
    color: var(--button-color);
    fill: var(--icon-color);
    font-family: var(--primary-font);
    line-height: 0;
    transition-property: visibility, opacity;
    transition-duration: var(--transition-duration);
}

.button__content>svg,
.button__loading>svg {
    width: 24px;
    height: 24px;
}

.button--string .button__loading>svg {
    width: 1em;
    height: 1em;
}

.button--selected {
    font-weight: bolder;
}

.button--selected .button__content {
    color: var(--button-color);
    fill: var(--font-color);
}

.button--loading .button__content {
    visibility: hidden;
    opacity: 0;
}

.button--string .button__content {
    line-height: normal;
}

.button__loading {
    position: absolute;
    display: flex;
    inset: 0;
    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity;
    transition-duration: var(--transition-duration);
}

.button--loading .button__loading {
    visibility: visible;
    opacity: 1;
}

.button__loading * {
    fill: var(--button-color);
}

.button__loading>svg {
    margin: auto;
}