.signup {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    background: var(--background-color-1);
}

.signup__banner {
    flex-shrink: 0;
    height: 30%;
    background-image: linear-gradient(0deg, transparent, var(--background-color-1)),
        url(../assets/images/signup-banner.webp);
    background-size: cover;
    background-position: 40%;
}

.signup__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-xxl);
    width: 100%;
    height: 100%;
    padding: var(--padding);
    border-radius: var(--border-radius-lg);
    background: linear-gradient(0deg, var(--background-color-5), var(--background-color-1));
}

.signup__title {
    margin-top: auto;
    color: var(--font-color);
    text-align: center;
    font-size: 1.4rem;
}

.signup__inputs,
.signup__buttons {
    display: flex;
    gap: var(--gap-xl);
    width: 100%;
    max-width: 500px;
}

.signup__inputs {
    flex-direction: column;
}

.signup__buttons {
    justify-content: flex-end;
    z-index: 1;
    margin-bottom: auto;
}

@media only screen and (orientation: landscape) {
    .signup {
        flex-direction: row;
        overflow-y: hidden;
    }

    .signup__form {
        overflow-y: auto;
        background: linear-gradient(-135deg, var(--background-color-5), var(--background-color-1));
    }

    .signup__banner {
        width: 40%;
        height: 100%;
        background-image: linear-gradient(-90deg, transparent, var(--background-color-1)),
            url(../assets/images/signup-banner.webp);
    }
}