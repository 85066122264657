@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&family=Shantell+Sans&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

html,
#root,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  --primary-default-color: hsl(200, 100%, 50%);
  --secondary-default-color: hsl(0, 0%, 100%);
  --primary-color: var(--primary-default-color);
  --secondary-color: var(--secondary-default-color);
  --error-color: hsl(0, 100%, 65%);
  --success-color: hsl(145, 98%, 40%);
  --selected-color: color-mix(in srgb, var(--primary-color) 10%, rgb(255, 255, 255, 0));
  --primary-button-color: var(--secondary-color);
  --secondary-button-color: color-mix(in hsl, var(--secondary-color), var(--font-color) 60%);

  --primary-font: 'Rubik', sans-serif;
  --secondary-font: 'Shantell Sans', cursive;

  --shadow-options: 0 0 2px 1px;
  --shadow-options-lg: 0 0 8px 2px;
  --shadow-color: hsl(0, 0%, 0%, 0.5);
  --shadow: var(--shadow-options) var(--shadow-color);
  --surface-shadow: var(--shadow);

  --transition-duration: 0.2s;
  --timing-function: cubic-bezier(0.47, 1.64, 0.41, 0.8);

  --padding-xs: 4px;
  --padding-sm: 8px;
  --padding: 16px;
  --padding-lg: 32px;

  --gap-sm: 4px;
  --gap: 8px;
  --gap-lg: 16px;
  --gap-xl: 32px;
  --gap-xxl: 64px;

  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-lg: 16px;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

svg {
  outline: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-track {
  margin: var(--padding);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  border-radius: var(--border-radius-sm);
}

.touch-only-device ::-webkit-scrollbar {
  display: none;
}

:has(.dark-mode) {
  --background-color-1: hsl(220, 5%, 8%);
  --background-color-2: hsl(220, 5%, 10%);
  --background-color-3: hsl(220, 5%, 12%);
  --background-color-4: hsl(220, 5%, 14%);
  --background-color-5: hsl(220, 5%, 16%);
  --cover-background-color: hsla(220, 5%, 16%, 0.9);
  --input-background-color: hsla(220, 5%, 92%, 0.1);
  --placeholder-color: hsla(220, 5%, 92%, 0.3);
  --hover-color: hsla(220, 5%, 92%, 0.1);
  --font-color: lightgray;
  --icon-color: hsl(0, 0%, 64%);
}

:has(.light-mode) {
  --background-color-1: hsl(220, 5%, 68%);
  --background-color-2: hsl(220, 5%, 74%);
  --background-color-3: hsl(220, 5%, 80%);
  --background-color-4: hsl(220, 5%, 86%);
  --background-color-5: hsl(220, 5%, 92%);
  --cover-background-color: hsla(220, 5%, 92%, 0.9);
  --input-background-color: hsla(220, 5%, 16%, 0.15);
  --placeholder-color: hsla(220, 5%, 16%, 0.3);
  --hover-color: hsla(220, 5%, 16%, 0.15);
  --font-color: black;
  --icon-color: hsl(0, 0%, 36%);
}