.image-picker {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    color: var(--primary-color);
    font-size: 1rem;
}

.image-picker__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.image-picker__upload {
    width: 100%;
    max-width: 300px;
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 50%;
    background: var(--input-background-color);
    cursor: pointer;
    transition: opacity 0.1s;
}

.image-picker__upload:hover {
    opacity: 0.5;
}

.image-picker__upload-input {
    display: none;
}

.image-picker__image {
    height: 100%;
    background-size: cover;
    background-position: center;
}