.panels {
    --panel-gap: var(--gap);

    display: flex;
    width: 100%;
    height: 100%;
    gap: var(--panel-gap);
}

.panels__left,
.panels__right {
    display: flex;
    flex-shrink: 0;
    z-index: 1;
    height: 100%;
    min-height: 0;
    background: var(--background-color-1);
}

.panels__left {
    gap: var(--panel-gap);
}

.panels__middle {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-radius: var(--border-radius-lg);
    background: var(--background-color-1);
    box-shadow: var(--shadow);
}

.panels__right {
    flex-direction: column;
    width: 200px;
}

.fullscreen .panels__right {
    width: 250px;
}

.touch-only-device .panels {
    --side-panel-size: 80%;
    --middle-panel-size: calc(100% - var(--panel-gap) * 4);
    --middle-panel-inset: calc((100% - var(--middle-panel-size)) / 2);
    --side-panel-outset: calc(var(--side-panel-size) - var(--middle-panel-inset) + var(--panel-gap));
    --selected-panel-translation: calc(var(--side-panel-outset) * var(--selected-panel) * -1);

    display: grid;
    grid-template-columns: var(--side-panel-size) var(--middle-panel-size) var(--side-panel-size);
    translate: var(--selected-panel-translation);
    transition: translate var(--transition-duration);
}

.touch-only-device .panels__left,
.touch-only-device .panels__middle,
.touch-only-device .panels__right {
    transition: filter var(--transition-duration);

    &:not([data-selected="true"]) {
        filter: brightness(40%);
        border-radius: var(--border-radius-lg);

        & * {
            pointer-events: none;
        }
    }
}

.touch-only-device .panels__left,
.touch-only-device .panels__right {
    width: 100%;
}