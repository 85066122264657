.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  padding: 2vh;
}

.navbar__logo {
  height: 100%;

  & svg {
    width: 80px;
    height: 100%;
    filter: drop-shadow(2px 2px 5px black);
  }
}

.navbar__name {
  color: white;
  font-size: 3rem;
  font-weight: bolder;
  text-shadow: 2px 2px 5px black;
}

.navbar__name>span {
  color: var(--primary-default-color);
}

@media only screen and (orientation: landscape) {
  .navbar {
    justify-content: flex-start;
  }
}