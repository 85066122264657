.overview {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xxl);
    height: 100%;
    padding: var(--padding-lg);
    overflow-x: hidden;
    color: var(--font-color);
}

.overview__welcome {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.overview__avatar {
    flex-shrink: 0;
    width: 300px;
    filter: drop-shadow(0 0 4px black);
}

.overview__title {
    position: absolute;
    top: 70%;
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: center;
    text-shadow: 0 0 5px black;
    font-size: calc(2rem + 5vmin);
    translate: 0 -50%;
    filter: drop-shadow(0 0 10px black);
}

.overview__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--gap-lg);
}

.overview__paragraphs {
    display: grid;
    gap: var(--gap);
    max-width: 60ch;
}

.overview__paragraphs span {
    color: var(--primary-color);
    font-weight: bolder;
    font-style: italic;
}

.overview__buttons {
    display: flex;
    align-items: center;
    gap: var(--gap-lg);
}