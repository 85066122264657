.theme,
.theme__list {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
    color: var(--primary-color);
}

.theme__list {
    gap: var(--gap);
}

.theme__label,
.theme__list-label {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    overflow-x: hidden;
}

.theme__description,
.theme__list-description {
    color: var(--font-color);
}

.theme__content {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
}

.theme__color-pickers {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-lg);

    &>* {
        flex: 1;
    }
}

.theme__list-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--gap-lg);
    overflow-x: hidden;
}

.theme__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);
    margin: 0;
    padding: 0;
}

.theme__button {
    width: 64px;
    aspect-ratio: 1;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: border-radius var(--transition-duration);

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}