.toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    gap: 12px;
    width: 100%;
    overflow: hidden;
    color: var(--primary-color);
}

.toggle__label {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    pointer-events: none;
}

.toggle__description {
    color: var(--font-color);
}

.toggle .react-toggle {
    position: relative;
    align-self: flex-start;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-x;
    cursor: pointer;
}

.toggle .react-toggle-screenreader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

.toggle .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity var(--transition-duration);
    -moz-transition: opacity var(--transition-duration);
    transition: opacity var(--transition-duration);
}

.toggle .react-toggle-track {
    width: 50px;
    height: 24px;
    border-radius: var(--border-radius-lg);
    background-color: var(--background-color-5);
    -webkit-transition: all var(--transition-duration);
    -moz-transition: all var(--transition-duration);
    transition: all var(--transition-duration);
}

.toggle .react-toggle:hover:not(.toggle .react-toggle--disabled) .react-toggle-track {
    background-color: var(--background-color-4);
}

.toggle .react-toggle--checked .react-toggle-track {
    background-color: hsl(126, 75%, 40%);
}

.toggle .react-toggle--checked:hover:not(.toggle .react-toggle--disabled) .react-toggle-track {
    background-color: hsl(126, 75%, 30%);
}

.toggle .react-toggle-track-check,
.toggle .react-toggle--checked .react-toggle-track-check,
.toggle .react-toggle-track-x {
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.toggle .react-toggle-track-check,
.toggle .react-toggle-track-x {
    position: absolute;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
}

.toggle .react-toggle-track-check {
    width: 14px;
    left: 8px;
    opacity: 0;
}

.toggle .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
}

.toggle .react-toggle-track-x {
    width: 10px;
    right: 10px;
    opacity: 1;
}

.toggle .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.toggle .react-toggle-thumb {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: var(--border-radius-lg);
    background-color: white;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.toggle .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    box-shadow: var(--shadow);
}