.gallery-channel {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.gallery-channel__upload-progresses {
    padding: var(--padding);
    padding-bottom: 0;
}

.gallery-channel__preview-box {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    padding: var(--padding);
}

.gallery-channel__previews-border {
    padding-block: var(--padding);
    border-radius: var(--border-radius);
    background: var(--input-background-color);
}

.gallery-channel__previews {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    max-height: 400px;
    padding-inline: var(--padding);
    overflow-x: hidden;
}

.gallery-channel__previews::-webkit-scrollbar-track {
    margin: 0;
}

.gallery-channel__preview {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    padding: var(--padding);
    border-radius: var(--border-radius);
    background: var(--background-color-2);
}

.gallery-channel__preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap);
    color: var(--font-color);
}

.gallery-channel__preview-body {
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
    overflow: hidden;
}

.gallery-channel__preview-details {
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
    width: 100%;
}

.gallery-channel__editor {
    display: flex;
    gap: var(--gap);
}

.gallery-channel__upload {
    display: flex;
    align-items: center;
    gap: var(--gap);
    padding: var(--padding);
    width: 100%;
    height: 1rem;
    box-sizing: content-box;
    border-radius: var(--border-radius);
    background: var(--input-background-color);
    color: var(--placeholder-color);
    cursor: pointer;
}

.gallery-channel__upload>svg,
.gallery-channel__send>svg {
    width: 24px;
    height: 24px;
}

.gallery-channel__upload-input {
    display: none;
}

.gallery-channel__send {
    display: flex;
    align-items: center;
    padding-inline: var(--padding);
    border: none;
    background: none;
    cursor: pointer;
    transition: opacity var(--transition-duration);

    &:enabled:hover>svg {
        translate: 4px 0;
    }

    &>svg {
        fill: var(--primary-color);
        transition: translate var(--transition-duration);
    }
}

.gallery-channel__send:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.exhibits {
    --image-gap: var(--gap);
    flex-basis: 100%;
    gap: var(--image-gap);
    padding: var(--padding);
    columns: 3 250px;
    overflow: auto;
}

.exhibits__exhibit {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    padding: var(--padding);
    break-inside: avoid;
    margin-block-end: var(--image-gap);
    border-radius: var(--border-radius);
    background: var(--background-color-2);
}

.exhibits__exhibit:last-child {
    margin-block-end: 0;
}

.exhibits__details {
    color: var(--font-color);
}

.exhibit-file {
    position: relative;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: var(--border-radius);
    background: var(--background-color-3);
}

.exhibit-file--preview .exhibit-file__embed,
.exhibit-file--preview .exhibit-file__spoiler {
    cursor: default;
}

.exhibit-file__embed {
    width: 100%;
    align-self: center;
    cursor: pointer;
    transition-property: filter, scale;
    transition-duration: var(--transition-duration);
}

.exhibit-file:not(.exhibit-file--preview) .exhibit-file__embed:hover {
    filter: brightness(1.2) saturate(2);
    scale: 1.1;
}

.exhibit-file__spoiler {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: var(--border-radius);
    text-align: center;
    color: var(--font-color);
    font-weight: bolder;
    letter-spacing: 0.05em;
    mix-blend-mode: hard-light;
    cursor: pointer;
    pointer-events: none;
    backdrop-filter: blur(30px);
    transition-property: opacity, backdrop-filter;
    transition-duration: var(--transition-duration);
}

.exhibit-file--spoilered .exhibit-file__spoiler {
    opacity: 1;
    pointer-events: all;
}

.exhibit-file__spoiler>svg {
    width: 56px;
    height: 56px;
    fill: var(--font-color);
}

@media only screen and (width > 1200px) {
    .gallery-channel__preview-body {
        flex-direction: row;
    }

    .exhibit-file--preview {
        width: 120px;
    }
}