.markdown {
    padding-block: 2px;
    overflow-wrap: break-word;
    white-space: break-spaces;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
    margin: 0;
}

.markdown p {
    margin: 0;
}

.markdown blockquote {
    margin: 0;
    padding-left: var(--padding-sm);
    border-left: 4px solid gray;
    color: color-mix(in oklch, var(--font-color), transparent 20%);
    white-space: normal;
}

.markdown blockquote p {
    white-space: break-spaces;
}

.markdown table {
    border-collapse: collapse;
}

.markdown table,
.markdown th,
.markdown td {
    border: 2px solid gray;
}

.markdown th,
.markdown td {
    padding: var(--padding-xs) var(--padding-sm);
    background: var(--input-background-color);
}

.markdown th {
    background: var(--secondary-color);
    color: var(--primary-color);
}

.markdown tr:nth-child(2n) {
    background: color-mix(in oklch, var(--input-background-color), transparent 20%);
}

.markdown ul {
    list-style-type: disc;
}

.markdown ul,
.markdown ol {
    padding: 0;
    list-style-position: inside;
    white-space: normal;
}

.markdown li::marker {
    color: gray;
}

.markdown li.task-list-item input[type='checkbox'] {
    margin: 0;
    margin-right: 4px;
}

.markdown pre {
    margin: 0;
}

.markdown code {
    padding: var(--padding-xs);
    border-radius: var(--border-radius-sm);
    background: var(--background-color-1);
}

.markdown>pre>code,
.markdown__code {
    display: block;
    width: max-content;
    max-width: 100%;
    margin: 0 !important;
    padding: var(--padding) !important;
    background: var(--background-color-1) !important;
    border-radius: var(--border-radius);
    border: 2px solid var(--background-color-3);
    white-space: normal;
}

.markdown__code>code {
    padding: 0 !important;
}

.markdown__code .linenumber {
    color: gray !important;
}

.markdown__table {
    width: max-content;
    max-width: 100%;
    padding: var(--padding);
    background: var(--background-color-1);
    border-radius: var(--border-radius);
    border: 2px solid var(--background-color-3);
    overflow: auto;
}

.markdown hr {
    border: none;
    border-bottom: 1px solid gray;
}