.block {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.block__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-lg);
    overflow-x: hidden;
}

.block__name {
    overflow: hidden;
    color: var(--primary-color);
    font-size: 2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.block__children {
    flex-shrink: 0;
    overflow: hidden;
    color: var(--font-color);
    line-height: 1.5;

    & p {
        margin: 0;
    }
}