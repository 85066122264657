.delete-message {
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
}

.delete-message__text {
    color: var(--font-color);
}

.delete-message__text>span {
    color: var(--primary-color);
}

.delete-message__button {
    display: flex;
    justify-content: flex-end;
}