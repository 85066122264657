.input {
    width: 100%;
    padding: var(--padding-sm);
    resize: none;
    outline: none;
    border: none;
    border-radius: var(--border-radius-sm);
    background: var(--input-background-color);
    color: var(--font-color);
    font-family: var(--primary-font);
    font-size: 1rem;
    transition: opacity var(--transition-duration);
}

.input::placeholder {
    color: var(--placeholder-color);
}

.input:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}