.message {
    position: relative;
    padding-inline: var(--padding-sm);
    border-radius: var(--border-radius);
    isolation: isolate;
}

.message:hover {
    z-index: 1;
    background: var(--background-color-2)
}

.message__user {
    display: flex;
    gap: var(--gap);
    margin-top: var(--padding);
    padding-block: var(--padding-sm);
}

.message__content {
    margin-left: 17px;
    padding-left: 25px;
    border-left: 2px solid var(--author-color);
    color: var(--font-color);
}

.message__image-container,
.message__video-container,
.message__song-container,
.message__tweet-container {
    padding-block: var(--padding-sm);
}

.message__image,
.message__video,
.message__song {
    border-radius: var(--border-radius-sm);
    border: none;
    cursor: zoom-in;
    transition: max-width var(--transition-duration);
}

.message__image:hover,
.message__video:hover,
.message__song:hover {
    max-width: 100%;
    transition: max-width var(--transition-duration) var(--transition-duration);
}

.message__image {
    display: block;
    max-width: clamp(0%, 500px, 100%);
    color: var(--error-color);
}

.message__video-container,
.message__song-container,
.message__tweet-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    isolation: isolate;
    max-width: 500px;
}

.message__video-container {
    aspect-ratio: 16/9;
}

.message__video,
.message__song {
    background: var(--background-color-2);
}

.message__video-loading,
.message__song-loading {
    position: absolute;
    z-index: -1;
    fill: var(--primary-color);
}

.message__video {
    overflow: hidden;
}

.message__song {
    height: 152px;
    border-radius: var(--border-radius-lg);
}

.message:hover .message__buttons {
    display: flex;
}

.message__buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    gap: var(--gap-sm);
    padding: var(--padding-sm);
    border-radius: var(--border-radius);
    background: var(--background-color-2);
}

.message__editing {
    padding: var(--padding);
    padding-left: 0;
    padding-bottom: var(--padding-xs);
}

.message__editing-textarea {
    width: 100%;
    padding: var(--padding);
    resize: none;
    outline: none;
    border: none;
    border-radius: var(--border-radius);
    background: var(--input-background-color);
    color: var(--font-color);
    line-height: 1;
    font-family: var(--primary-font);
    font-size: 1rem;
    transition: opacity var(--transition-duration);
}

.message__editing-textarea::placeholder {
    color: var(--placeholder-color);
}

.message__editing-buttons {
    font-size: 0.8rem;
    text-align: right;
}

.message__editing-buttons>button {
    padding: 0;
    border: none;
    background: none;
    color: var(--primary-color);
    font-family: var(--primary-font);
    cursor: pointer;
}

.message__editing-buttons>button:hover {
    text-decoration: underline;
}

.react-tweet-theme {
    --tweet-container-margin: 0 !important;
}