.settings {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.settings__error {
    padding: var(--padding);
}

.settings__content {
    height: 100%;
    overflow: auto;
}

.settings__default {
    display: flex;
    flex-direction: column;
}

.settings__controls {
    flex-basis: 100%;
    gap: var(--gap-xxl);
    padding: var(--padding-lg);
    overflow-x: hidden;
}

.settings__placeholder,
.settings__save {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-lg);
    padding: var(--padding);
}

.settings__placeholder {
    margin-inline: 10%;
    margin-bottom: var(--padding);
    visibility: hidden;
}

.settings__save {
    position: absolute;
    z-index: 1;
    inset-inline: 10%;
    bottom: var(--padding);
    border-radius: var(--border-radius-lg);
    background: var(--background-color-2);
    box-shadow: var(--shadow-options-lg) var(--shadow-color);
    color: var(--error-color);
}