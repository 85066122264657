.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 5vh;
  height: 0;
  padding: 2vw;
}

.content__text {
  color: lightgray;
  text-shadow: 2px 2px 5px black;
  text-align: center;
  font-family: var(--primary-font);
  font-size: calc(1rem + 1vw);
}

@media only screen and (orientation: landscape) {
  .content {
    flex-direction: row;
  }

  .content__text {
    max-width: 20rem;
  }
}