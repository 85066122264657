.chat-channel {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-channel__messages {
    flex-basis: 100%;
    overflow-x: hidden;
    padding: var(--padding);
    padding-bottom: 0;
}

.chat-channel__message-box {
    position: relative;
}

.chat-channel__typings {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    gap: var(--gap);
    width: 100%;
    padding-inline: var(--padding);

    &>svg {
        width: 1rem;
        height: 1rem;
    }
}

.chat-channel__typings>svg,
.chat-channel__typings>svg * {
    fill: var(--primary-color);
}

.chat-channel__typing {
    font-size: 1rem;
    font-style: italic;
}