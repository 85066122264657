.create-invite {
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
}

.create-invite__label {
    color: var(--font-color);
}

.create-invite__label>span {
    color: var(--primary-color);
}

.create-invite__code {
    padding: var(--padding);
    border-radius: var(--border-radius);
    background: var(--input-background-color);
    color: var(--error-color);
    font-size: 1rem;
    text-align: center;
}

.create-invite__code>span {
    display: block;
    color: var(--font-color);
    font-family: 'Verdana', 'Arial', 'Helvetica';
    font-weight: bolder;
    letter-spacing: 4px;
    user-select: text;
}

.create-invite__buttons {
    display: flex;
    justify-content: flex-end;
    gap: var(--gap-lg);
}