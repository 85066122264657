.updates {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xxl);
    height: 100%;
    padding: var(--padding-lg);
    overflow-x: hidden;
}

.updates__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0.5;
    text-align: right;
    color: var(--font-color);
    font-size: 0.8rem;
    white-space: nowrap;
}

.updates__bold {
    font-weight: bolder;
}

.updates__list {
    max-width: 700px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: var(--font-color);
    list-style: none;
    list-style-position: inside;
    line-height: 1.5;
}

.updates__list>li::before {
    content: '\268F';
    margin-right: 0.5rem;
    opacity: 0.5;
}