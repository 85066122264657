.warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap);
    width: 100%;
    height: 100%;
    padding: var(--padding);
    text-align: center;
}

.warning>svg {
    width: 100px;
    height: 100px;
}

.warning * {
    fill: currentColor;
}