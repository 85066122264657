.join-community {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.join-community__label {
    color: var(--font-color);

    &>span {
        color: var(--primary-color);
    }
}

.join-community__controls {
    display: flex;
    align-items: center;
    gap: var(--gap-lg);
}