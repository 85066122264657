.channel-area {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    min-height: 0;
    overflow: hidden;
}

.channel-area__header {
    flex-shrink: 0;
    padding: var(--padding);
    z-index: 1;
    overflow: hidden;
    background: var(--background-color-2);
    box-shadow: var(--surface-shadow);
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.2rem;
}

.channel-area__tab>.channel-area__header {
    color: var(--primary-color);
    text-align: center;
}

.channel-area__channel>.channel-area__header {
    color: var(--font-color);
}

.channel-area__body {
    flex-basis: 100%;
    min-height: 0;
}

.channel-area__content {
    height: 100%;
    background-color: var(--background-color-3);
}