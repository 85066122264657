.navigation-area {
    display: flex;
    flex-direction: column;
    width: 200px;
    min-width: 0;
    overflow: hidden;
    border-radius: var(--border-radius-lg);
    background: var(--background-color-3);
    box-shadow: var(--shadow);
}

.navigation-area__section-name,
.navigation-area__community-name {
    z-index: 1;
    flex-shrink: 0;
    padding: var(--padding);
    overflow: hidden;
    background: var(--background-color-2);
    white-space: nowrap;
    text-overflow: ellipsis;
    box-shadow: var(--surface-shadow);
}

.navigation-area__section-name>span {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: var(--primary-color);
    font-size: 1.2rem;
}

.navigation-area__tab-list,
.navigation-area__channel-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: var(--padding);
    overflow: auto;
}

.navigation-area__community-name>span {
    color: var(--font-color);
    font-size: 1.2rem;
}

.navigation-area__community-buttons {
    display: flex;
    gap: var(--gap-lg);
    margin-inline: var(--padding);
    padding-block: var(--padding);
    border-bottom: 2px solid var(--background-color-5);
}

.navigation-area__userbar {
    z-index: 1;
    flex-shrink: 0;
}

.fullscreen .navigation-area {
    width: 250px;
}

.touch-only-device .navigation-area {
    width: 100%;
}