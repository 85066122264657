.footer {
  display: flex;
  justify-content: center;
  gap: var(--gap-xl);
  padding: var(--padding-sm);
  padding-bottom: 0;
  color: var(--primary-default-color);
  font-size: calc(0.5rem + 0.2vw);
}

.footer__name,
.footer__flag {
  padding: var(--padding-sm);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 2px 2px 5px black;
  background: var(--background-color-5);
}

.footer__flag {
  --flag-width: 60px;
  position: relative;
  display: none;
  overflow: hidden;
  width: var(--flag-width);
  background: url(../assets/israel.svg);
  background-position: center;
  background-size: contain;
  border: 4px solid var(--background-color-5);
  border-bottom: none;
}

.footer__flag::after {
  --shine-width: 50px;
  --shine-offset: 50px;
  --shine-distance: calc(var(--flag-width) + var(--shine-width) + (var(--shine-offset) * 2));
  content: '';
  position: absolute;
  top: 0;
  left: calc((var(--shine-width) + var(--shine-offset)) * -1);
  width: var(--shine-width);
  height: 100%;
  background: white;
  transform: skewX(-30deg);
  filter: blur(10px);
  animation: shine 5s ease-in-out infinite;
}

@keyframes shine {
  30% {
    translate: var(--shine-distance);
  }

  100% {
    translate: var(--shine-distance);
  }
}