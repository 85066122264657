.user-display {
    display: flex;
    gap: var(--gap);
    width: 100%;
    min-width: 0;
    height: 36px;
}

.user-display__details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
}

.user-display__details>div,
.user-display__details>span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: text;
}

.user-display__details>span {
    opacity: 0.5;
    color: var(--font-color);
    font-size: 0.7rem;
}