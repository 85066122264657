.lobby__border,
.lobby {
    height: 100%;
    overflow: hidden;
    border-radius: var(--border-radius-lg);
}

.lobby__border {
    z-index: 1;
    width: 95%;
    padding: var(--padding-xs);
    box-shadow: 2px 2px 10px 2px black;
}

.fullscreen.lobby__border {
    position: absolute;
    width: 100%;
    top: 0;
    padding: 0;
    border-radius: 0;
}

.lobby {
    position: relative;
    display: flex;
    background: var(--background-color-1);
}

.fullscreen .lobby {
    border-radius: 0;
}

@media only screen and (orientation: landscape) {
    .lobby__border {
        width: 100%;
    }
}

@media (display-mode: standalone) {
    .fullscreen .lobby {
        overflow: visible;
        padding-top: var(--padding-sm);
    }
}