.message-box {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    padding: var(--padding);
}

.message-box__images-border {
    padding-inline: var(--padding);
    border-radius: var(--border-radius);
    background: var(--input-background-color);
}

.message-box__images {
    display: flex;
    gap: var(--gap);
    padding-block: var(--padding);
    overflow: auto;
}

.message-box__images::-webkit-scrollbar-track {
    margin: 0;
}

.message-box__image {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    padding: var(--padding);
    border-radius: var(--border-radius);
    background: var(--background-color-2);
}

.message-box__image-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap);
    color: var(--font-color);
}

.message-box__image-header>span {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.message-box__image-body {
    display: flex;
    justify-content: center;
    height: 120px;
    overflow: hidden;
    border-radius: var(--border-radius);
    background: var(--background-color-3);
}

.message-box__input-send {
    display: flex;
    gap: var(--gap);
}

.message-box__input {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.message-box__upload {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    padding-inline: var(--padding);

    &>svg {
        fill: var(--icon-color);
        cursor: pointer;

        &:hover {
            fill: var(--font-color);
        }
    }
}

.message-box__upload>svg,
.message-box__send>svg {
    width: 24px;
    height: 24px;
}

.message-box__upload-input {
    display: none;
}

.message-box__textarea,
.message-box__send {
    border-radius: var(--border-radius);
}

.message-box__textarea:disabled,
.message-box__send:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.message-box__textarea {
    width: 100%;
    padding: var(--padding);
    padding-right: 52px;
    resize: none;
    outline: none;
    border: none;
    background: var(--input-background-color);
    color: var(--font-color);
    line-height: 1;
    font-family: var(--primary-font);
    font-size: 1rem;
    transition: opacity var(--transition-duration);
}

.message-box__textarea::placeholder {
    color: var(--placeholder-color);
}

.message-box__send {
    display: flex;
    align-items: center;
    padding-inline: var(--padding);
    border: none;
    background: none;
    cursor: pointer;
    transition: opacity var(--transition-duration);

    &:enabled:hover>svg {
        translate: 4px 0;
    }

    &>svg {
        fill: var(--primary-color);
        transition: translate var(--transition-duration);
    }
}