.dropdown {
    --secondary-background: transparent;

    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    background: linear-gradient(transparent, var(--secondary-background));
    transition: --secondary-background var(--transition-duration);

}

.dropdown--open {
    --secondary-background: var(--background-color-1);
}

.dropdown__wrapper,
.dropdown__list {
    transition-property: height, opacity, translate;
    transition-duration: var(--transition-duration);
    transition-timing-function: ease-in-out;
}

.dropdown__wrapper {
    overflow-y: clip;
}

.dropdown__list {
    display: flex;
    flex-direction: column;
    padding: var(--padding-sm);
    color: var(--icon-color);
    translate: 0 -100%;

    .dropdown--open & {
        translate: 0 0;
    }
}

.dropdown__icon-header {
    display: flex;
    justify-content: center;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    transition: scale var(--transition-duration);

    &:hover {
        scale: 1.1;
    }

    &:active {
        scale: 1;
    }

    &>svg {
        width: 48px;
        height: 48px;
    }
}

.dropdown__channel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-sm) var(--padding);
    border-radius: var(--border-radius);
    cursor: pointer;

    .dropdown--open &,
    &:hover {
        background: var(--hover-color);

        &>.dropdown__channel-button {
            display: flex;
        }
    }

    .dropdown--highlighted & {
        background: var(--selected-color);
    }
}

.dropdown__channel-label {
    display: flex;
    align-items: center;
    gap: var(--gap-lg);
    overflow: hidden;
    color: var(--font-color);
    font-size: 1rem;
    font-family: var(--primary-font);
    text-align: left;

    &>svg {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        fill: var(--font-color);
    }

    &>span {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.dropdown__channel-button {
    display: none;
    padding: 0;
    border: none;
    background: none;
    color: var(--font-color);
    font-size: 1rem;
    font-family: var(--primary-font);
    cursor: pointer;

    &>svg {
        width: 16px;
        height: 16px;
        fill: var(--icon-color);
    }

    &:hover>svg {
        fill: var(--font-color);
    }
}

.dropdown__channel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-lg);
    padding: var(--padding-sm);
    border-radius: var(--border-radius);
    border: none;
    background: none;
    color: var(--icon-color);
    font-size: 1rem;
    font-family: var(--primary-font);
    cursor: pointer;

    &:hover {
        background: var(--hover-color);
    }

    &>svg {
        width: 16px;
        height: 16px;
        fill: var(--icon-color);
    }
}

.dropdown__section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-lg);
    padding: var(--padding-sm) var(--padding);
    border-radius: var(--border-radius);
    border: none;
    background: none;
    color: var(--font-color);
    font-size: 1rem;
    font-family: var(--primary-font);
    cursor: pointer;

    &>svg {
        width: 16px;
        height: 16px;
        fill: var(--font-color);
    }

    &:hover {
        background: var(--hover-color);
    }

    .dropdown--highlighted & {
        background: var(--selected-color);
    }
}

@property --secondary-background {
    syntax: "<color>";
    inherits: false;
    initial-value: white;
}