.recipe {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
    height: 100%;
    overflow-x: hidden;
}

.recipe__image {
    flex-shrink: 0;
    width: 100%;
    height: 300px;
    background-image: linear-gradient(transparent, var(--background-color-3)),
        url(../assets/images/brownie.webp);
    background-size: cover;
    background-position: 30%;
}

.recipe__body {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xxl);
    padding: var(--padding-lg);
}


.recipe__ingredients,
.recipe__preparation {
    padding: 0;
    margin: 0;
    list-style: none;
    counter-reset: preparation 0;
}

.recipe__ingredients>li::before,
.recipe__preparation>li::before {
    margin-right: 0.5rem;
    opacity: 0.5;
}

.recipe__ingredients>li::before {
    content: '\2765';
}

.recipe__preparation>li::before {
    counter-increment: preparation;
    content: counter(preparation) '. ';
}

.recipe__finished>span {
    background: linear-gradient(90deg, violet, var(--primary-color), var(--secondary-color), violet);
    background-size: 200%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-weight: bolder;
    animation: recipe__animate-gradient 2s linear infinite;
}

@keyframes recipe__animate-gradient {
    100% {
        background-position: 200%;
    }
}

@media only screen and (orientation: landscape) {
    .recipe__body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        &>:first-child,
        &>:last-child {
            grid-column: span 3;
        }

        &>:nth-child(3) {
            grid-column: span 2;
        }
    }
}