.profile-settings {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-xxl);
    width: 100%;

    &>* {
        flex: 1 1;
    }
}

.profile-settings__options {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xxl);
    width: 100%;
}

.profile-settings__preview {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    color: var(--primary-color);
}

.profile-settings__preview-user {
    padding: var(--padding-sm);
    border-radius: var(--border-radius);
}