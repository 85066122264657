.color-picker {
    width: 100%;
}

.color-picker__input {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gap);
}

.color-picker__button {
    position: absolute;
    right: 0;
    width: 1.5rem;
    aspect-ratio: 1;
    margin: 6px;
    border-radius: var(--border-radius-sm);
    border: none;
    cursor: pointer;
}

.color-picker__display {
    position: absolute;
    z-index: 2;
    display: flex;
    gap: var(--gap);
    inset-inline: 10%;
    bottom: var(--padding);
    padding: var(--padding);
    border-radius: var(--border-radius-lg);
    background: var(--background-color-2);
    box-shadow: var(--shadow-options-lg) var(--shadow-color);
}

.color-picker__preview {
    flex-shrink: 0;
    width: 25%;
    border-radius: var(--border-radius);
}

.color-picker .react-colorful {
    gap: var(--gap);
    width: 100%;
    isolation: isolate;
}

.color-picker .react-colorful__hue,
.color-picker .react-colorful__saturation {
    border-radius: var(--border-radius);
    border: none;
    cursor: crosshair;
}

.color-picker .react-colorful__hue {
    height: 16px;
}

.color-picker .react-colorful__hue-pointer,
.color-picker .react-colorful__saturation-pointer {
    width: 16px;
    height: 16px;
}