.custom-area {
    overflow: hidden;
    border-top-left-radius: var(--border-radius-lg);
    border-bottom-left-radius: var(--border-radius-lg);
    background: var(--background-color-3);
    box-shadow: var(--shadow);
}

.custom-area,
.custom-area__default,
.custom-area__community {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.custom-area__default {
    opacity: 0.2;
    background-image: url(../assets/images/panel-banner.webp);
    background-position: 48%;
    background-size: cover;
    cursor: url(../assets/anchor.png) 16 1, default;
}

.custom-area__bar {
    padding: var(--padding);
    background: var(--background-color-2);
    box-shadow: var(--surface-shadow);
    color: var(--font-color);
    font-size: 1.2rem;
}