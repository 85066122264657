.tooltip {
    --tooltip-color: var(--background-color-1);
    --tooltip-background-color: var(--icon-color);
}

.tooltip[data-theme~='main-style'] {
    border-radius: var(--border-radius);
    background: var(--tooltip-background-color);
    box-shadow: var(--shadow);
    color: var(--tooltip-color);
    line-height: normal;
    font-family: var(--primary-font);
    font-size: 1rem;
    transition-property: opacity, scale;
    transition-duration: var(--transition-duration);
    transition-timing-function: var(--timing-function);
}

.tooltip[data-animation='main-style'][data-state='hidden'] {
    opacity: 0;
    scale: 0;
}





.tooltip[data-theme~='main-style'][data-placement^='top'] {
    transform-origin: bottom;
}

.tooltip[data-theme~='main-style'][data-placement^='bottom'] {
    transform-origin: top;
}

.tooltip[data-theme~='main-style'][data-placement^='left'] {
    transform-origin: right;
}

.tooltip[data-theme~='main-style'][data-placement^='right'] {
    transform-origin: left;
}





.tooltip[data-theme~='main-style'][data-placement^='top']>.tippy-arrow::before {
    border-top-color: var(--tooltip-background-color);
}

.tooltip[data-theme~='main-style'][data-placement^='bottom']>.tippy-arrow::before {
    border-bottom-color: var(--tooltip-background-color);
}

.tooltip[data-theme~='main-style'][data-placement^='left']>.tippy-arrow::before {
    border-left-color: var(--tooltip-background-color);
}

.tooltip[data-theme~='main-style'][data-placement^='right']>.tippy-arrow::before {
    border-right-color: var(--tooltip-background-color);
}