.add-community {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: var(--gap-lg);

    &>span {
        color: var(--font-color);
        font-size: 1.5rem;
    }
}