.user-avatar {
    flex-shrink: 0;
    overflow: hidden;
    aspect-ratio: 1;
    border-radius: 50%;
}

.user-avatar__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}